import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import aicreat from '../views/aicreat/aicreat.vue'

const routes = [
  {path: '/',name: 'aicreat', component: aicreat,meta:{title:"AI数字人"}},
  {path: '/login',name: 'login', component: HomeView,meta:{title:"欢迎登录"}},
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from , next) => {
  if(sessionStorage.getItem('netTitle')==null){
    sessionStorage.setItem('netTitle',"")
  }
  const title = (to.meta.title ? to.meta.title : '') 
  document.title = title
  // 判断是否含有登录标识
  const isLogin = sessionStorage.getItem('token')
  console.log("isLogin="+isLogin)
  // 想要跳转到 person页面 但是没有标识 就next 去登录
    if(!isLogin){
      if(to.name=="login"){
        next()
      }else{
        router.push('login')
      }
    }else{
      next()
    }
})

export default router
